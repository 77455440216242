import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import {Title} from "@angular/platform-browser";
import {UserService} from "@services/user.service";
import {GcdmWorkerComponent} from "./_components/gcdm-worker/gcdm-worker.component";
import { Router} from "@angular/router";
import {VehicleInfoService} from "@services/vehicle-info.service";
import {DataServiceService} from "@services/data-service.service";
import {AuthenticationService} from "@services/authentication.service";
import { TronGenericPageTrackingService } from './_helpers/TRON-Analytics-tracking/page-tracking/tron-generic-page-tracking.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  title = 'customer-portal-service-house';
  loadAppPage: boolean = false;
  prospect: any;
  pageRendering!: string;
  loading: boolean = false;
  headerCustDataParent: any;
  apiUrl: string = '';
  brand: string = 'bmw';
  brandToChild: string = 'BMW';

  constructor(
    private titleService: Title,
    private userSvc: UserService,
    private router: Router,
    private authSvc: AuthenticationService,
    private dataSvc: DataServiceService,
    private gcdmWCom: GcdmWorkerComponent,
    private vehSvc: VehicleInfoService,
    private tronGenericPageTrackingService: TronGenericPageTrackingService
  ) {
    this.checkBrand(window.location.href);
    let code: any = this.getParamAccess('code');
    let expires_in: any = this.getParamAccess('expires_in');

    if (
      code !== null ||
      expires_in !== null ||
      sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN') ||
      window.location.href.indexOf('localhost') != -1 ||
      window.location.pathname.indexOf('psteml') != -1
    ) {
      // console.log("Pathname ::::", window.location.pathname);
      // console.log("psteml", window.location.pathname.indexOf('psteml')!=-1)
      if (sessionStorage.getItem('CP_OAUTH_BEARER_TOKEN')) {
        this.loadAppPage = true;
      } else if (window.location.pathname.indexOf('psteml') != -1) {
        this.loadAppPage = true;
      } else {
        let authenticationObservable = this.userSvc.gcdmLogin(code);
        authenticationObservable.subscribe((gcdmObject) => {
          sessionStorage.setItem(
            'CP_OAUTH_BEARER_TOKEN',
            gcdmObject.access_token
          );
          let userServiceObservable =
            this.userSvc.getGcdmCustomerProfileOnePage(gcdmObject.access_token);
          let userAccountObservable = this.userSvc.getGcdmUserAccount(
            gcdmObject.access_token
          );
          userServiceObservable.subscribe((customerDetails) => {
            userAccountObservable.subscribe((UserAccount) => {
              this.loadCheckProspect(customerDetails, UserAccount);
            });

            customerDetails.forEach(
              (o: any) => {
                //
                if (o === undefined) {
                  // console.log("business partner is undefined");
                  // this.loadAppPage = true;
                  // } else {
                  this.loadAppPage = false;

                  window.location.href =
                    environment.gcdm_login_env_hostname +
                    '/oneid/#/login?client=customerportal&country=ZA&language=en&brand=bmw&scope=authenticate_user%20openid&response_type=code&redirect_uri=' +
                    this.apiUrl +
                    '%2Fgcdm%2Findex%2Fauth&state=fjdsfjdkjfs382038402983409';
                }

                // @ts-ignore
                this.headerCustDataParent = {
                  name: o.givenName,
                  surname: o.surname,
                  title: this.titleList(o.salutation),
                };
              },
              (error: any) => {
                this.loadAppPage = false;
                window.location.href =
                  environment.gcdm_login_env_hostname +
                  '/oneid/#/login?client=customerportal&country=ZA&language=en&brand=bmw&scope=authenticate_user%20openid&response_type=code&redirect_uri=' +
                  this.apiUrl +
                  '%2Fgcdm%2Findex%2Fauth&state=fjdsfjdkjfs382038402983409';
              }
            );
          });
        });
      }
    }

  }

  loadCheckProspect(customerDetails: any, userAccount: any) {
    this.vehSvc.getVehicleInfo().subscribe(
      (prospect: any) => {
        this.loading = true;
        this.prospect = prospect;
        this.dataSvc.setGcdmCustBp(this.prospect.bpNo);
        if (this.prospect.bpNo === 'empty') {
          this.loading = true;
          this.loadAppPage = true;
          this.pageRendering = 'prospect';
        } else {
          let isPresent = null;
          if (this.prospect.vehicles.findvehicle !== null) {
            isPresent = this.prospect.vehicles.findvehicle.find(
              (p: { currentVehicle: string }) => p.currentVehicle === 'X'
            );
          }
          if (!isPresent) {
            this.loading = true;
            this.loadAppPage = true;
            this.pageRendering = 'prospect';
          } else {
            let shortBrand =
              this.brandToChild === 'BMW'
                ? ['BM', 'BI']
                : this.brandToChild === 'MINI'
                ? 'MI'
                : 'MO';
            const isTheCorrectBrand = this.prospect.vehicles.findvehicle.find(
              (p: { brand: any }) =>
                p.brand.includes(
                  this.getBrand_Switch(this.brandToChild, p.brand)
                )
            );
            if (!isTheCorrectBrand) {
              this.loadAppPage = false;
              this.dataSvc.removeItems();
              this.dataSvc.clearStore();
              sessionStorage.clear();
              this.dataSvc.setCurrentVehicles('');
              let miniBrand = this.prospect.vehicles.findvehicle.find(
                (p: { brand: any }) => p.brand === 'MI'
              );
              let motorradBrand = this.prospect.vehicles.findvehicle.find(
                (p: { brand: any }) => p.brand === 'MO'
              );

              window.location.href =
                environment.gcdm_login_env_hostname +
                '/oneid/#/login?client=customerportal&country=ZA&language=en&brand=' +
                this.ssoBrandSwitch(isPresent.brand) +
                '&scope=authenticate_user%20openid&response_type=code&redirect_uri=' +
                this.ssoPageSwitch(isPresent.brand) +
                '%2Fgcdm%2Findex%2Fauth&state=' +
                environment.state;

              if (motorradBrand) {
                window.location.href =
                  environment.gcdm_login_env_hostname +
                  '/oneid/#/login?client=customerportal&country=ZA&language=en&brand=bmwmotorrad&scope=authenticate_user%20openid&response_type=code&redirect_uri=' +
                  environment.apiURLMotorrad +
                  '%2Fgcdm%2Findex%2Fauth&state=' +
                  environment.state;
              } else {
              }
            }
            this.loading = true;
            this.loadAppPage = true;
            this.pageRendering = 'service-dashboard';
          }
        }

        this.gcdmWCom.loadCPData(
          customerDetails,
          userAccount,
          this.pageRendering
        );
        // this.getCustomerProfile(customerDetails, this.pageRendering);
      },
      (error) => {
        console.error(error);
        this.logout();
      }
    );
  }

  getParamAccess(code: string) {
    const results = new RegExp('[\\?&#]' + code + '=([^&#]*)').exec(
      window.location.href
    );
    if (!results) {
      return null;
    }
    return results[1] || null;
  }

  //DONT' DO ANY TOKEN STUFF - HANDLED BY GcDM / BACKEND
  ngOnInit() {
    // this.getToken();
    this.tronGenericPageTrackingService.setPageName(
      `'Customer Portal | ${this.brand} | at App Component'` 
    );
    console.log('inside app.comp ng oninit');
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  titleList(title: string) {
    if (title === 'SAL_MR') {
      return 'Mr';
    }

    if (title === 'SAL_MS') {
      return 'Ms';
    }

    if (title === 'SAL_MRS') {
      return 'Mrs';
    }

    if (title === 'SAL_MISS') {
      return 'Miss';
    }

    if (title === 'SAL_DR') {
      return 'Dr';
    }
    return '';
  }

  logout() {
    this.dataSvc.removeItems();
    this.dataSvc.clearStore();
    sessionStorage.clear();
    this.authSvc.gcdmLogOff().subscribe();
    window.location.href =
      environment.gcdm_login_env_hostname +
      '/oneid/#/logout?client=customerportal&redirectUrl=https%3A%2F%2Fwww.' +
      this.brand +
      '.co.za%2Fen%2Findex.html';
  }

  checkBrand(hostUrl: string) {
    if (hostUrl.indexOf('bmw') > -1) {
      this.setTitle('BMW Customer Portal');
      this.apiUrl = environment.apiURL;
      this.brand = 'bmw';
    } else if (hostUrl.indexOf('mini') > -1) {
      this.setTitle('MINI Customer Portal');
      this.apiUrl = environment.apiURLMini;
      this.brand = 'mini';
    } else if (hostUrl.indexOf('mot') > -1) {
      this.setTitle('Motorrad Customer Portal');
      this.apiUrl = environment.apiURLMotorrad;
      this.brand = 'mot';
    }
    this.brandToChild = this.brand.toUpperCase();
    this.loadStyle(this.brand);
    this.dataSvc.setBrand(this.brandToChild);
  }

  loadStyle(styleName: string) {
    let icon = document.querySelectorAll(
      'link[rel=icon]'
    )[0] as HTMLLinkElement;
    let themeLink = document.getElementById('brandstyle') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = styleName + '.css';
    } else {
      const style = document.createElement('link');
      style.id = 'brandstyle';
      style.rel = 'stylesheet';
      style.href = styleName + '.css';

      document.head.appendChild(style);
    }

    if (icon) {
      icon.href = 'assets/' + styleName.toUpperCase() + '/images/favicon.ico';
    } else {
      const favicon = document.createElement('link');
      favicon.rel = 'icon';
      favicon.type = 'image/x-icon';
      favicon.href =
        'assets/' + styleName.toUpperCase() + '/images/favicon.ico';
      document.head.appendChild(favicon);
    }
  }

  getBrandSwitch(val: string) {
    var answer = ['BMW'];
    switch (val) {
      case 'MINI':
        answer = ['MI'];

        break;
      case 'BMW':
        answer = ['BM', 'BI'];

        break;
      case 'MOT':
        answer = ['MO'];

        break;
    }

    return answer;
  }

  ssoPageSwitch(val: string) {
    var url = environment.apiURL;
    switch (val) {
      case 'MI':
        // url = environment.apiURL.replace('bmw.co.za','mini.co.za').replace('bmw-motorrad.co.za', 'mini.co.za');

        url = environment.apiURLMini;
        break;
      case 'BM':
        url = environment.apiURL
          .replace('mini.co.za', 'bmw.co.za')
          .replace('bmw-motorrad.co.za', 'bmw.co.za');
        // url = 'https://www.account-d.bmw.co.za';
        break;

      case 'BI':
        url = environment.apiURL
          .replace('mini.co.za', 'bmw.co.za')
          .replace('bmw-motorrad.co.za', 'bmw.co.za');
        // url = 'https://www.account-d.bmw.co.za';
        break;
      case 'MO':
        url = environment.apiURL
          .replace('mini.co.za', 'bmw-motorrad.co.za')
          .replace('bmw.co.za', 'bmw-motorrad.co.za');
        // url = environment.apiURLMotorrad
        break;
    }

    return url;
  }

  ssoBrandSwitch(val: string) {
    var answer = 'bmw';
    switch (val) {
      case 'MI':
        answer = 'mini';
        break;
      case 'BM':
        answer = 'bmw';
        break;
      case 'BI':
        answer = 'bmw';
        break;
      case 'MO':
        answer = 'bmwmotorrad';
        break;
    }
    return answer;
  }

  getBrand_Switch(brand: string, vehicleBrand: string) {
    if (brand === 'BMW') {
      if (vehicleBrand === 'BI') {
        return 'BI';
      } else {
        return 'BM';
      }
    }

    if (brand === 'MINI') {
      return 'MI';
    }

    if (brand === 'MOT') {
      return 'MO';
    }
    return '';
  }
}


